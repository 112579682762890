interface Values {
  country: string;
  twoYearsAuditedFinancial: boolean;
  yearOfIncorporation: number;
  amountOfFinancingSoughtInUSD: number;
  finRevenue: string;
}

export default function isQualified(values: Values) {
  const admittedCountry = { label: "Uganda" };

  const admittedYearsOfOperation = 2;
  const currentYear = new Date().getUTCFullYear();
  const numberOfYearsInOperation = currentYear - values.yearOfIncorporation;
  const minimumAmountRaised = 500000;
  const minimumfinRevenue = 250000;

  const byCountry = values.country === admittedCountry.label;
  const byYearsOfAuditedFinance = values.twoYearsAuditedFinancial;
  const byYearsOfOperation =
    numberOfYearsInOperation >= admittedYearsOfOperation;
  const byAmountRaised =
    values.amountOfFinancingSoughtInUSD >= minimumAmountRaised;
  const byfinRevenue = parseInt(values.finRevenue) >= minimumfinRevenue;

  return (
    byCountry &&
    byYearsOfAuditedFinance &&
    byYearsOfOperation &&
    byAmountRaised &&
    byfinRevenue
  );
}
