import axios from "@/core/services/axios";
import router from "@/router/clean";
import isQualified from "@/core/helpers/checkQualification";
import qs from "qs";

const SET_CURRENT_STEP = "SET_CURRENT_STEP";
const SET_SUBMISSION_STATUS = "SET_SUBMISSION_STATUS";
const SET_SUBMISSION_INFO = "SET_SUBMISSION_INFO";
const SET_INTERMEDIARY_LIST = "SET_INTERMEDIARY_LIST";
const SET_ADVISORS_INFO = "SET_ADVISORS_INFO";
const SET_MATCH_LIST = "SET_MATCH_LIST";

const state = {
  step: 1,
  status: {
    step: 1,
    view: "",
  },
  submission: {},
  intermediaryList: {
    type: "",
    list: [],
  },
  advisorsInformation: [],
  matchList: [],
};

const mutations = {
  [SET_CURRENT_STEP](state, payload) {
    state.step = payload;
  },
  [SET_SUBMISSION_STATUS](state, payload) {
    state.status = payload;
  },
  [SET_SUBMISSION_INFO](state, payload) {
    state.submission = payload;
  },
  [SET_INTERMEDIARY_LIST](state, payload) {
    state.intermediaryList = payload;
  },
  [SET_ADVISORS_INFO](state, payload) {
    state.advisorsInformation = payload;
  },
  [SET_MATCH_LIST](state, payload) {
    state.matchList = payload;
  },
};

const actions = {
  createNewSubmission({ commit, dispatch, rootGetters }) {
    const user = rootGetters["user/currentUser"];
    const data = {
      status: {
        step: 1,
        view: "application",
      },
      submission: {
        interest: {},
        company: {},
        lead: {
          email: user.email,
          givenName: user.firstName,
          familyName: user.lastName,
        },
      },
    };
    return axios
      .post("/alien/submissions?entity=investee", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO, submission);

        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  sendApplicationStep({ commit, dispatch }, payload) {
    var status = {};
    if (
      isQualified({
        country: payload.company.baseCountry,
        yearOfIncorporation: payload.company.yearOfIncorporation,
        twoYearsAuditedFinancial: !!payload.interest.twoYearsAuditedFinancial,
        amountOfFinancingSoughtInUSD:
          payload.interest.amountOfFinancingSoughtInUSD,
        finRevenue: payload.interest.finRevenue,
      })
    ) {
      status = { step: 2, view: "onboarding" };
    } else {
      status = {
        step: 2,
        view: "unqualified",
      };
    }

    var data = {
      submission: payload,
      status,
    };
    const currentSubmission = state.submission;
    delete currentSubmission._id;

    return axios
      .post("/alien/submissions?entity=investee", {
        ...currentSubmission,
        platform: "fsd",
        data,
      })
      .then(() => {
        dispatch(
          "messages/showSuccessMessage",
          {
            text: "Thank you for submitting your application!",
            confirmButtonText: "Ok",
          },
          { root: true }
        ).then(() => {
          commit(SET_SUBMISSION_STATUS, status);
          dispatch("setCurrentUserSubmission");
        });
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setCurrentStep({ commit }, payload) {
    commit(SET_CURRENT_STEP, payload);
    const steps = {
      1: "application",
      2: "onboarding",
      3: "review",
      4: "bdr",
      5: "investment",
    };
    router.push({ name: steps[payload] });
  },
  setCurrentUserSubmission({ commit, dispatch, rootGetters }) {
    const userId = rootGetters["user/userId"];
    return axios
      .get("/alien/submissions?entity=investee")
      .then((response) => {
        const userSubmissions = response.data.items.filter(
          (item) => item.userId === userId
        );
        const userSubmission = userSubmissions[0];
        if (userSubmission) {
          const status = userSubmission.data.status;
          commit(SET_SUBMISSION_STATUS, status);

          const submission = userSubmission;
          commit(SET_SUBMISSION_INFO, submission);

          dispatch("setCurrentStep", status.step);
        }
        return userSubmission;
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setNewStatus({ dispatch, state, commit }, payload) {
    var data = {
      submission: state.submission,
      status: payload,
    };
    return axios
      .post("/alien/submissions?entity=investee", {
        platform: "fsd",
        data,
      })
      .then((response) => {
        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS, status);
        dispatch("setCurrentStep", status.step);
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  updateSubmission({ dispatch, commit, state }, { submission, status }) {
    var data = {
      submission,
      status,
    };
    const currentSubmission = state.submission;
    delete currentSubmission._id;
    return axios
      .post("/alien/submissions?entity=investee", {
        ...currentSubmission,
        data,
      })
      .then((response) => {
        const submission = response.data;
        commit(SET_SUBMISSION_INFO, submission);

        const status = response.data.data.status;
        commit(SET_SUBMISSION_STATUS, status);
        dispatch("setCurrentStep", status.step);
      });
  },
  setIntermediaryList({ commit, dispatch }, payload) {
    return axios
      .get("/alien/available-intermediaries", { params: { type: payload } })
      .then((response) => {
        commit(SET_INTERMEDIARY_LIST, {
          list: response.data.items,
          type: payload,
        });
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  updateAdvisorObject({ dispatch }, payload) {
    return axios
      .patch("/alien/advisors/" + payload._id, payload)
      .then(() => {
        dispatch("setAdvisorInformation");
      })
      .catch((err) => {
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        dispatch(
          "messages/showErrorMessage",
          {
            mainText: errorMessage,
            buttonText: "Try again!",
          },
          { root: true }
        );
      });
  },
  setAdvisorInformation({ commit, state }) {
    const submissionId = state?.submission?.submissionId;
    return axios
      .get(`/alien/advisors?investeeSubmissionId=${submissionId}`)
      .then((response) => {
        commit(SET_ADVISORS_INFO, response.data.items);
      });
  },
  setMatchList({ state, commit }, payload) {
    const submissionId = state?.submission?.submissionId;
    return axios
      .get("/alien/matches", {
        params: { investeeSubmissionId: submissionId, status: payload },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
      .then((response) => {
        commit(SET_MATCH_LIST, response.data.items);
      });
  },
  updateMatchObject({ dispatch }, payload) {
    return axios.patch("/alien/match/" + payload._id, payload).then(() => {
      dispatch("setMatchList", "inProgress");
    });
  },
};

const getters = {
  currentStep: (state) => state.step,
  submission: (state) => state.submission?.data?.submission,
  isStepFinished: (state) => (num) => state.status.step > num,
  view: (state) => state.status.view,
  status: (state) => state.status,
  intermediaryListInfo: (state) => state.intermediaryList,
  advisorsInformation: (state) => state.advisorsInformation,
  matchList: (state) => state.matchList,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
