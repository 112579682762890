import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import VueGtag from "vue-gtag";
import ApiService from "@/core/services/ApiService";
import AppConfig from "@/config/app.config";
import packageJson from "../package.json";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { createMetaManager } from "vue-meta";

import "@/core/plugins/prismjs";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(createMetaManager());

if (AppConfig.trackingGA) {
  app.use(
    VueGtag,
    {
      config: { id: AppConfig.trackingGA },
    },
    router
  );
}

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
